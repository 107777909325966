<template>
    <div class="zpanle">
        <div style="background:#fff;padding:20px;">
            <div class="baccount-tit">
                <h3 class="g-title">银行卡信息</h3>
            </div>
            <div class="bkcard-wrap" style="border:none;">
                <div class="bkcard-bd">
                    <el-form class="g-form" :model="bankform" label-width="100px">
                        <el-form-item label="持卡人">
                            <el-input v-model="bankform.name" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="身份证号码">
                            <el-input v-model="bankform.idcard" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="银行">
                            <el-input v-model="bankform.card_bank" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="卡号">
                            <el-input v-model="bankform.card_no" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="卡类型">
                            <el-input v-model="bankform.card_type" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="卡所在地">
                            <el-input v-model="bankform.address" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="银行支行" v-show="bankform.card_type == 1">
                            <el-input v-model="bankform.card_bank_branch" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="有效期" readonly v-show="bankform.card_type == 2">
                            <el-input v-model="bankform.credit_card_endtime"></el-input>
                        </el-form-item>

                        <el-form-item label="安全码" v-show="bankform.card_type == 2">
                            <el-input v-model="bankform.credit_card_code" readonly></el-input>
                        </el-form-item>

                        <el-form-item label="手机号">
                            <el-input v-model="bankform.mobile" readonly></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="del" style="width:100px;">删除</el-button>
                            <router-link to="/user/wallet/list?tx=1">
                                <el-button>返回</el-button>
                            </router-link>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "myBankCard",
    metaInfo() {
        return {
            title: "银行卡信息 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            bankform: {}
        };
    },
    created() {
        this.loadData(); //加在数据
    },
    methods: {
        loadData() {
            let _this = this;
            _this.post(
                "/pay/pay/my_bankcard",
                { id: this.$route.query.id },
                function(res) {
                    if (res.code == 200) {
                        _this.bankform = res.data;
                    }
                }
            );
        },

        //删除
        del() {
            let _this = this;

            _this
                .$confirm("确认删除此银行卡信息吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                })
                .then(() => {
                    _this.post(
                        "/pay/pay/del_bankcard",
                        { id: _this.$route.query.id },
                        function(res) {
                            if (res.code == 200) {
                                _this.$message({
                                    type: "success",
                                    message: "删除成功"
                                });
                                setTimeout(() => {
                                    _this.$router.push(
                                        "/user/wallet/list?tx=1"
                                    );
                                }, 1000);
                            } else {
                                if (res.msg) {
                                    _this.$message.error(res.msg);
                                } else {
                                    _this.$message.error("删除失败");
                                }
                            }
                        }
                    );
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="less">
@import "../../../styles/mywallet.less";
</style>




